import { createContext, FC, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AUTH_TOKEN_KEY = '@authToken';
const ENV = (process.env["ENV"] || 'Development' );

type LookupResponse = {
  fields: any;
  data: any;
};

type ApiInterface = {
  lookup: (email: string) => Promise<LookupResponse>;
  sendNewPassword: (userId: string) => Promise<boolean>;
  sendPasswordInstructions: (userId: string) => Promise<boolean>;
  isAuthenticated: boolean;
  verifyPassword: (password: string) => Promise<boolean>;
  cancelSubscription: (endOfTerm: boolean, userId: string) => Promise<boolean>;
  extendSubscription: (termEndsAt: number, userId: string) => Promise<boolean>;
  vsOneUpgrade: (userId: string) => Promise<boolean>;
  changeEmail: (newEmail: string, userId: string) => Promise<boolean>;
  toggleAccountDeletion: (userId: string) => Promise<boolean>;
};

function BASE_URL(path: string): string {
  if (Platform.OS === 'web') {
    if (window.location.hostname === 'localhost') {
      return 'http://api.virtualshield.me:3000/freshdesk/' + path;
    }

    let c = [];
    c.push(window.location.protocol + '//');
    c.push(window.location.hostname.replace('lookup', 'api-v2'));
    if (window.location.port) {
      c.push(':' + window.location.port);
    }

    c.push('/freshdesk/' + path);
    return c.join('');
  } else {
    return 'https://api.virtualshield.com/freshdesk/' + path;
  }
}

function OPTIONS(method: 'GET' | 'POST', authToken?: string): RequestInit {
  let options = {
    method: method,
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (authToken) {
    let headers = { ...options.headers, 'HTTP-X-FRESHDESK-AUTHORIZATION': authToken };
    options.headers = headers;
  }

  return options as RequestInit;
}

function apiFetch(url: string, method: 'GET' | 'POST', authToken?: string): Promise<Response> {
  return fetch(BASE_URL(url), OPTIONS(method, authToken));
}

// @ts-ignore
const ApiContext = createContext<ApiInterface>(null);

export const ApiProvider: FC = ({ children }) => {
  const [authorizationToken, setAuthorizationToken] = useState<string>();
  const isAuthenticated = (authorizationToken || '')?.length > 0;

  useEffect(() => {
    (async () => {
      try {
        const result = await AsyncStorage.getItem(AUTH_TOKEN_KEY);
        if (result != null) {
          setAuthorizationToken(result);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (authorizationToken && authorizationToken.length > 0) {
          await AsyncStorage.setItem(AUTH_TOKEN_KEY, authorizationToken);
        } else {
          await AsyncStorage.removeItem(AUTH_TOKEN_KEY);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [authorizationToken]);

  const lookup = (email: string): Promise<LookupResponse> => {
    return new Promise<LookupResponse>((resolve, reject) => {
      apiFetch(`user_info?email=${encodeURIComponent(email)}`, 'GET', authorizationToken)
        .then((r) => {
          if (r.ok) {
            resolve(r.json());
          } else {
            throw new Error('Customer not found');
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
        });
    });
  };

  const vsOneUpgrade = (userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      apiFetch(`vs_one_upgrade/${encodeURIComponent(userId)}`, 'POST', authorizationToken)
        .then((r) => {
          resolve(r.status === 204);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const sendNewPassword = (userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      apiFetch(`send_new_password/${encodeURIComponent(userId)}`, 'POST', authorizationToken)
        .then((r) => {
          resolve(r.status === 204);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const sendPasswordInstructions = (userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      apiFetch(
        `send_reset_password_instructions/${encodeURIComponent(userId)}`,
        'POST',
        authorizationToken
      )
        .then((r) => {
          resolve(r.status === 204);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const verifyPassword = (password: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      apiFetch(`authenticate?password=${encodeURIComponent(password)}`, 'POST')
        .then((r) => {
          if (r.status === 200) {
            return r.json();
          } else {
            reject('Password invalid');
          }
        })
        .then((json) => {
          setAuthorizationToken(json.token);
          resolve(json.token.length > 0);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const cancelSubscription = (endOfTerm: boolean, userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      apiFetch(
        `cancel_subscription/${encodeURIComponent(userId)}?end_of_term=${endOfTerm}`,
        'POST',
        authorizationToken
      )
        .then((r) => {
          resolve(r.status === 204);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const extendSubscription = (termEndsAt: number, userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      apiFetch(
        `extend_subscription/${encodeURIComponent(userId)}?term_ends_at=${termEndsAt}`,
        'POST',
        authorizationToken
      )
        .then((r) => {
          resolve(r.status === 204);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const toggleAccountDeletion = (userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      apiFetch(
        `toggle_account_deletion/${encodeURIComponent(userId)}`,
        'POST',
        authorizationToken
      )
        .then((r) => {
          resolve(r.status === 204);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const changeEmail = (newEmail: string, userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      apiFetch(
        `change_email/${encodeURIComponent(userId)}?new_email=${encodeURIComponent(newEmail)}`,
        'POST',
        authorizationToken
      )
        .then((r) => {
          resolve(r.status === 204);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const value = {
    lookup,
    sendNewPassword,
    sendPasswordInstructions,
    isAuthenticated,
    verifyPassword,
    cancelSubscription,
    extendSubscription,
    changeEmail,
    toggleAccountDeletion,
    vsOneUpgrade
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = (): ApiInterface => {
  return useContext(ApiContext);
};
