import React, { VFC } from 'react';
import { useApi } from '../utils/api';
import { Linking, Text } from 'react-native';

type Props = {
  userId: string;
};

export const SendPasswordInstructions: VFC<Props> = ({ userId }) => {
  const api = useApi();

  const requestSendNewPassword = async () => {
    if (confirm('Are you sure you want to send reset password instructions to this user?')) {
      try {
        if (await api.sendPasswordInstructions(userId)) {
          alert('Password reset instructions has been sent to the user');
        } else {
          alert('There was a problem sending instructions');
        }
      } catch {
        alert('There was a problem sending instructions');
      }
    }
  };

  return <Text onPress={requestSendNewPassword}>Send password instructions</Text>;
};
