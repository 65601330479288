import { ActivityIndicator, StyleSheet, TextInput, View } from 'react-native';
import { useEffect, useMemo, useState, VFC } from 'react';
import debounce from 'lodash.debounce';
import { genShadowStyle } from '../utils/shadows';

type Props = {
  callback: (debouncedEmail: string) => void;
  isLoading: boolean;
};

export const DebouncedEmailField: VFC<Props> = ({ callback, isLoading }) => {
  const [email, setEmail] = useState<string>('');
  const debouncedEmail = useMemo(() => debounce(callback, 300), []);

  useEffect(() => {
    debouncedEmail(email);
    return () => {
      debouncedEmail.cancel();
    };
  }, [email]);

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.field}
        placeholder={'Fill in email'}
        value={email}
        autoCompleteType={'email'}
        textContentType={'emailAddress'}
        keyboardType={'email-address'}
        autoCapitalize={'none'}
        autoCorrect={false}
        onChangeText={setEmail}
      />
      <ActivityIndicator style={{ opacity: isLoading ? 1.0 : 0.0 }} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    ...genShadowStyle(2),
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 8,
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
  },
  field: {
    flex: 1,
    marginRight: 10,
  },
});
