import { VFC } from 'react';
import { Button as NativeButton } from 'react-native';

type Props = {
  title: string;
  enabled: boolean;
  onPress: () => void;
};

export const Button: VFC<Props> = ({ title, enabled, onPress }) => {
  return <NativeButton title={title} onPress={onPress} disabled={!enabled} />;
};
