import { StyleSheet, Text, View } from 'react-native';
import { VFC } from 'react';

type Props = {
  message: string | undefined | null;
};

export const ErrorMessage: VFC<Props> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{message}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 15,
    backgroundColor: 'rgba(222, 128, 16, 0.15)',
    textAlign: 'center',
    borderRadius: 8,
    marginTop: 10,
  },
  text: {
    color: '#DE8010',
  },
});
