import React, { VFC } from 'react';
import { useApi } from '../utils/api';
import { Linking, Text } from 'react-native';

type Props = {
  userId: string;
};

export const CancelSubscriptionImmediately: VFC<Props> = ({ userId }) => {
  const api = useApi();

  const action = async () => {
    if (
      confirm(
        'Are you sure you want to cancel subscription immediately? User will be refunded if within 60 days of purchase.'
      )
    ) {
      try {
        if (await api.cancelSubscription(false, userId)) {
          alert('Subscription has been cancelled');
        } else {
          alert('There was a problem cancelling subscription');
        }
      } catch {
        alert('There was a problem cancelling subscription');
      }
    }
  };

  return <Text onPress={action}>Cancel Subscription Immediately</Text>;
};
