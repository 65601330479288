import React, { VFC } from 'react';
import { Text, Linking } from 'react-native';

type Props = {
  chargebeeId: string;
};

/**
 * Open Customer in Chargebee
 *
 * An action to open customer in chargebee
 */
export const OpenCustomerInChargebee: VFC<Props> = ({ chargebeeId }) => {
  return (
    <Text
      onPress={() => {
        Linking.openURL(
          `https://virtualshield.chargebee.com/admin-console/customers/${chargebeeId}`
        );
      }}>
      Open Customer in Chargebee
    </Text>
  );
};
