import { useApi } from '../utils/api';
import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { DebouncedEmailField } from '../fragments/DebouncedEmailField';
import { ErrorMessage } from '../fragments/ErrorMessage';
import { CustomerFields } from '../fragments/CustomerFields';
import { OpenCustomerInChargebee } from '../fragments/OpenInChargebee';
import { SendNewPassword } from '../fragments/SendNewPassword';
import { SendPasswordInstructions } from '../fragments/SendPasswordInstructions';
import { StatusBar } from 'expo-status-bar';
import { CancelSubscriptionImmediately } from '../fragments/CancelSubscriptionImmediately';
import { DisableAutoRenewal } from '../fragments/DisableAutoRenewal';
import { ExtendSubscription } from '../fragments/ExtendSubscription';
import { QueryParams } from '../utils/query';
import { ChangeEmail } from "../fragments/ChangeEmail";
import { AccountDeletion } from "../fragments/AccountDeletion";
import { VsOneUpgrade } from "../fragments/VsOneUpgrade";

export const CustomerInformation = () => {
  // The api hook
  const api = useApi();

  const [email, setEmail] = useState<string>('');

  // If any errors a message is present here
  const [error, setError] = useState<string | null>(null);

  // If something is loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // A list of fields from the backend in the format of [{"Name": "Rasmus", ...}, ...]
  const [fields, setFields] = useState([]);

  // An object with special data returned by the backend that
  // can be mapped manually, for exmaple for links etc
  const [data, setData] = useState({});

  /// Returns query params (?x=y)
  const queryParams = QueryParams<{ email?: string }>();

  // Returns true if email field should be enabled. It should only be
  // enabled when email is not provied by query string
  const emailFieldEnabled = queryParams?.email === undefined;

  /**
   * Reset error message if loading is set to true meaning
   * the user started some process
   */
  useEffect(() => {
    if (isLoading) {
      setError(null);
      setData({});
      setFields([]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (queryParams && queryParams.email && queryParams.email.length > 0) {
      emailCallback(queryParams.email);
    }
  }, [queryParams.email]);

  /**
   * This callback is called when the user is finihsed typing an email. We then
   * starts the loading animation and calls the backend and waits for a
   * response.
   *
   * If no errors we save the data and hopefully it should be rendered ;-)
   *
   * @param email
   */
  const emailCallback = async (email: string) => {
    if (email.length === 0) {
      return;
    }

    setEmail(email);
    setIsLoading(true);

    try {
      const result = await api.lookup(email);
      console.log(result);
      setFields(result.fields);
      setData(result.data);
    } catch (error) {
      console.log(error);
      setError(error || 'Customer not found');
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Refreshes the customer information
   */
  const refreshData = () => {
    emailCallback(email);
  }

  const nextBillingAtObj = fields.find((fields) => fields['next_billing_at']);
  const nextBillingAt = nextBillingAtObj ? nextBillingAtObj['next_billing_at'] : null;
  const deletionRequestedAt = fields.find((fields) => fields['deletion_requested_at']);

  return (
    <>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[
          styles.container,
          emailFieldEnabled ? {} : { marginTop: 0, padding: 0 },
        ]}>
        {emailFieldEnabled && (
          <DebouncedEmailField callback={emailCallback} isLoading={isLoading} />
        )}
        <ErrorMessage message={error} />
        {!error && (
          <>
            <View style={styles.actions}>
              {data['chargebee_id'] && (
                <OpenCustomerInChargebee chargebeeId={data['chargebee_id']} />
              )}
              {data['id'] && <SendNewPassword userId={data['id']} />}
              {data['id'] && <SendPasswordInstructions userId={data['id']} />}
              {data['id'] && <ChangeEmail userId={data['id']} />}
              <View style={{ height: 20 }} />
              {data['id'] && <CancelSubscriptionImmediately userId={data['id']} />}
              {data['id'] && <AccountDeletion userId={data['id']} deletionRequestedAt={deletionRequestedAt} onChanged={refreshData} />}
              {data['id'] && <DisableAutoRenewal userId={data['id']} />}
              {data['id'] && <VsOneUpgrade userId={data['id']} />}
              {data['id'] && nextBillingAt && (
                <ExtendSubscription userId={data['id']} nextBillingAt={nextBillingAt} />
              )}
            </View>
            <CustomerFields fields={fields} padding={emailFieldEnabled ? 20 : 0} />
          </>
        )}
        <View style={{ height: 100 }} />
      </ScrollView>
      <StatusBar style="auto" />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#fff',
    marginTop: 50,
  },
  actions: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
});
