import { VFC } from 'react';
import { StyleSheet, Text, View } from 'react-native';

type Props = {
  fields: any;
  padding: number;
};

export const CustomerFields: VFC<Props> = ({ fields, padding }) =>
  fields.map((fieldGroup) => (
    <View style={[styles.container, { padding }]} key={Math.random()}>
      {Object.keys(fieldGroup).map((key) => (
        <View key={key}>
          <Text style={styles.title}>{key.replaceAll('_', ' ')}</Text>
          <Text style={styles.body}>{fieldGroup[key] || '-'}</Text>
        </View>
      ))}
    </View>
  ));

const styles = StyleSheet.create({
  container: {
    width: '100%',
    textAlign: 'left',
    borderRadius: 8,
    borderBottomColor: '#f1f1f1',
    borderBottomWidth: 1,
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginTop: 5,
  },
  body: {
    marginBottom: 5,
  },
});
