import React, { VFC } from 'react';
import { useApi } from '../utils/api';
import { Linking, Text } from 'react-native';
import moment from 'moment-timezone';

type Props = {
  userId: string;
  nextBillingAt: string;
};

export const ExtendSubscription: VFC<Props> = ({ userId, nextBillingAt }) => {
  const api = useApi();

  const action = async () => {
    const defaultValue = moment(nextBillingAt, 'YYYY-MM-DD hh:mm:ss')
      .tz('America/Los_Angeles')
      .format('MM/DD/YYYY');

    const termEnd = prompt('When should current term end?', defaultValue) || '';
    const newTermEnd = moment(termEnd).tz('America/Los_Angeles');

    if (termEnd.length > 0 && newTermEnd.isValid()) {
      try {
        if (await api.extendSubscription(newTermEnd.unix(), userId)) {
          alert('Subscription has been extended.');
        } else {
          alert('There was a problem extending subscription');
        }
      } catch {
        alert('There was a problem extending subscription');
      }
    }
  };

  return <Text onPress={action}>Extend Subscription</Text>;
};
