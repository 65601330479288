import { useState } from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { PasswordField } from '../fragments/PasswordField';
import { Button } from '../fragments/Button';
import { useApi } from '../utils/api';
import { ErrorMessage } from '../fragments/ErrorMessage';

export const Login = () => {
  // The api hook
  const api = useApi();

  // If any errors a message is present here
  const [error, setError] = useState<string | null>(null);

  // If something is loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // The password that was entered by the user
  const [password, setPassword] = useState<string>('');

  /**
   * Calls backend and verifies the user entered password. If
   * password correct App.tsx will rerender the new view.
   */
  const verifyPassword = async () => {
    if (password.length === 0) {
      return;
    }

    setIsLoading(true);

    try {
      await api.verifyPassword(password);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.form}>
        <Text style={styles.title}>Login</Text>
        <ErrorMessage message={error} />
        <PasswordField password={password} passwordChanged={setPassword} isLoading={isLoading} />
        <View style={styles.actions}>
          <Button title={'Login'} enabled={!isLoading} onPress={verifyPassword} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#fff',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    alignItems: 'center',
    width: 300,
  },
  title: {
    fontWeight: 'bold',
  },
  actions: {
    marginTop: 10,
    alignSelf: 'flex-end',
  },
});
