import { injectWebCss } from './utils/webcss';
import { CustomerInformation } from './pages/CustomerInformation';
import { ApiProvider, useApi } from './utils/api';
import { Login } from './pages/Login';
import { useEffect } from 'react';

const Router = () => {
  const api = useApi();

  if (api.isAuthenticated) {
    return <CustomerInformation />;
  } else {
    return <Login />;
  }
};

export default function App() {
  return (
    <ApiProvider>
      <Router />
    </ApiProvider>
  );
}

// Inject some special web css
injectWebCss();
