import React, { VFC } from 'react';
import { useApi } from '../utils/api';
import { Linking, Text } from 'react-native';
import moment from 'moment-timezone';

type Props = {
  userId: string;
};

export const VsOneUpgrade: VFC<Props> = ({ userId }) => {
  const api = useApi();

  const action = async () => {
    if (confirm('Are you sure you want to upgrade to VS One?')) {
      try {
        if (await api.vsOneUpgrade(userId)) {
          alert('User was upgrade to VS One.');
        } else {
          alert('There was a problem upgrading the user');
        }
      } catch (e) {
        console.log(e)
        alert('There was a problem upgrading the user');
      }
    }
  };

  return <Text onPress={action}>Upgrade to VS One</Text>;
};
