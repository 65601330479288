import React, { VFC } from 'react';
import { useApi } from '../utils/api';
import { Linking, Text } from 'react-native';

type Props = {
  userId: string;
};

export const DisableAutoRenewal: VFC<Props> = ({ userId }) => {
  const api = useApi();

  const action = async () => {
    if (
      confirm('Are you sure you want to disable auto renewal? Subscription will end at terms end.')
    ) {
      try {
        if (await api.cancelSubscription(true, userId)) {
          alert('Auto Renewal has been disabled');
        } else {
          alert('There was a problem disabling auto renewal');
        }
      } catch {
        alert('There was a problem disabling auto renewal');
      }
    }
  };

  return <Text onPress={action}>Disable Auto Renewal</Text>;
};
