import React, { VFC } from 'react';
import { useApi } from '../utils/api';
import { Text } from 'react-native';

type Props = {
  userId: string;
};

export const ChangeEmail: VFC<Props> = ({ userId }) => {
  const api = useApi();

  const action = async () => {
    const newEmail = prompt('Enter new email for customer') || '';

    if (newEmail.length > 0 && newEmail.includes("@") && newEmail.includes(".")) {
      try {
        if (await api.changeEmail(newEmail, userId)) {
          alert('Change Email Request has been initiated. Customer needs to approve the request by accepting the change on their new email.');
        } else {
          alert('There was a problem changing email');
        }
      } catch {
        alert('There was a problem changing email');
      }
    }
  };

  return <Text onPress={action}>Change Email</Text>;
};
