import React, { VFC } from 'react';
import { useApi } from '../utils/api';
import { Linking, Text } from 'react-native';

type Props = {
  userId: string;
  deletionRequestedAt: string | null
  onChanged: () => void
};

export const AccountDeletion: VFC<Props> = ({ userId , deletionRequestedAt, onChanged}) => {
  const api = useApi();

  const action = async () => {
    const msg = deletionRequestedAt ? 'Are you sure you want to cancel account deletion?' : 'Are you sure you want to request account deletion?';
    if (
      confirm(
        msg
      )
    ) {
      try {
        if (await api.toggleAccountDeletion(userId)) {
          if (deletionRequestedAt) {
            alert('Account deletion cancelled');
          } else {
            alert('Account deletion requested');
          }

          onChanged();
        }
      } catch {
        alert('There was a problem requesting account deletion');
      }
    }
  };

  if (deletionRequestedAt) {
    return <Text onPress={action}>Cancel Account Deletion</Text>;
  } else {
    return <Text onPress={action}>Request Account Deletion</Text>;
  }
};
